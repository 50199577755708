<template>
  <section
    id="posologieMedicaments"
    class="min-h-screen w-full flex flex-col gap-y-[24px]"
  >
    <!-- HEADER -->
    <header class="h-[66px] bg-white py-[24px] px-[54px] w-full">
      <h1 class="text-[14px] text-[#9191a8] font-EffraR">Posologie</h1>
    </header>

    <main class="pl-3 lg:pl-[54px] pr-3 lg:pr-[116px] mb-5">
      <!-- CONTENT  -->
      <ais-instant-search
        index-name="dokma_dev_drugs"
        v-bind:search-client="searchClient"
      >
        <ais-autocomplete>
          <div slot-scope="{ currentRefinement, indices, refine }">
            <form class="w-full">
              <div class="relative text-gray-600 w-full">
                <span
                  class="
                  absolute
                  left-0
                  h-full
                  flex
                  items-center
                  justify-center
                  ml-3
                "
                >
                  <i class="fas fa-search text-gray-500 text-2xl"></i>
                </span>
                <input
                  type="search"
                  :value="currentRefinement"
                  @input="refine($event.currentTarget.value)"
                  @click="currentRefinement = ''"
                  placeholder="Recherchez la posologie des médicaments par nom, famille ou composition"
                  class="
                  bg-white
                  rounded-t-cu
                  pl-12
                  py-5
                  text-base
                  w-full
                  border-0
                  border-r
                  border-l
                  border-t
                  border-b lg:border-b-0
                  border-solid
                  border-gray-300
                  focus:outline-none
                "
                />
              </div>
            </form>
            <div class="flex flex-col">
              <!-- HEADER -->
              <div
                class="hidden lg:grid grid-cols-6 gap-x-6 bg-dokBlue-lighterr px-2 border border-t-0 border-solid border-gray-300"
              >
                <div class="col-span-2 text-sm py-5 text-gray-600 font-EffraM">
                  Nom
                </div>
                <div class="text-sm py-5 text-gray-600 font-EffraM">
                  Composition
                </div>
                <div class="text-sm py-5 text-gray-600 font-EffraM">
                  Famille
                </div>
                <div class="text-sm py-5 text-gray-600 font-EffraM">
                  PPV
                </div>
                <div class="text-sm py-5 text-gray-600 font-EffraM">
                  Plus d'info
                </div>
              </div>
              <!-- CONTENT -->
              <div
                class="grid grid-cols-1 lg:grid-cols-6 gap-x-6 bg-white px-2 border border-t-0 border-solid border-gray-300"
                v-for="(data, i) in indices[0].hits"
                :key="i"
              >
                <div
                  class="col-span-1 lg:col-span-2 text-sm py-5 text-gray-600 font-EffraM flex flex-col"
                >
                  <span class="block lg:hidden font-EffraM text-black text-base"
                    >Nom :
                  </span>
                  {{ data.name }}
                </div>
                <div class="text-sm py-5 text-gray-600 font-EffraM">
                  <span class="block lg:hidden font-EffraM text-black text-base"
                    >Composition :
                  </span>
                  {{ data.composition ? data.composition.join(", ") : "--" }}
                </div>
                <div class="text-sm py-5 text-gray-600 font-EffraM">
                  <span class="block lg:hidden font-EffraM text-black text-base"
                    >Famille :
                  </span>
                  {{ data.famille || "--" }}
                </div>
                <div class="text-sm py-5 text-gray-600 font-EffraM">
                  <span class="block lg:hidden font-EffraM text-black text-base"
                    >PPV :
                  </span>
                  {{ data.ppv || "--" }}
                </div>
                <div class="text-sm py-5 text-gray-600 font-EffraM">
                  <i
                    class="far fa-eye cursor-pointer text-xl"
                    @click="openDrug(data)"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </ais-autocomplete>
      </ais-instant-search>
    </main>

    <!-- MODAL TO SHOW MEDICAMENT -->
    <modal
      :title="data.name"
      :is-active="modalIsOpen"
      :exit-modal="exitModal"
      class="modal-posology"
    >
      <div class="grid gap-2 grid-cols-2 px-2 py-3">
        <div class="text-gray-600 text-lg font-EffraL font-semibold">
          Quantité
        </div>
        <div class="text-blackdok text-lg font-EffraR">
          {{ data.presentation || "--" }}
        </div>
      </div>
      <div class="grid gap-2 grid-cols-2 bg-gray-100 px-2 py-3">
        <div class="text-gray-700 text-lg font-EffraL font-semibold">
          Fabriquant
        </div>
        <div class="text-blackdok text-lg font-EffraR">
          {{ data.distributeur || "--" }}
        </div>
      </div>
      <div class="grid gap-2 grid-cols-2 px-2 py-3">
        <div class="text-gray-600 text-lg font-EffraL font-semibold">
          Composition
        </div>
        <div class="text-blackdok text-lg font-EffraR">
          {{ data.composition ? data.composition.join(", ") : "--" }}
        </div>
      </div>
      <div class="grid gap-2 grid-cols-2 bg-gray-100 px-2 py-3">
        <div class="text-gray-700 text-lg font-EffraL font-semibold">
          Famille
        </div>
        <div class="text-blackdok text-lg font-EffraR">
          {{ data.famille || "--" }}
        </div>
      </div>
      <div class="grid gap-2 grid-cols-2 px-2 py-3">
        <div class="text-gray-600 text-lg font-EffraL font-semibold">
          Nature de produit
        </div>
        <div class="text-blackdok text-lg font-EffraR">
          {{ data.nature_produit || "--" }}
        </div>
      </div>
      <div class="grid gap-2 grid-cols-2 bg-gray-100 px-2 py-3">
        <div class="text-gray-700 text-lg font-EffraL font-semibold">PPV</div>
        <div class="text-blackdok text-lg font-EffraR">
          {{ data.ppv ? `${data.ppv} Dhs` : "--" }}
        </div>
      </div>
      <div class="grid gap-2 grid-cols-2 px-2 py-3">
        <div class="text-gray-600 text-lg font-EffraL font-semibold">
          Prix hospitalier
        </div>
        <div class="text-blackdok text-lg font-EffraR">
          {{ data.prix_hospitalier ? `${data.prix_hospitalier} Dhs` : "--" }}
        </div>
      </div>
      <div class="grid gap-2 grid-cols-2 bg-gray-100 px-2 py-3">
        <div class="text-gray-700 text-lg font-EffraL font-semibold">
          Rembourssement
        </div>
        <div
          class="font-semibold text-lg"
          :class="
            data.rembourssement === 'Oui' ? 'text-green-700' : 'text-red-700'
          "
        >
          {{ data.rembourssement || "--" }}
        </div>
      </div>
      <div class="grid gap-2 grid-cols-2 px-2 py-3">
        <div class="text-gray-600 text-lg font-EffraL font-semibold">
          Status
        </div>
        <div class="text-blackdok text-lg font-EffraR">
          {{ data.statut || "--" }}
        </div>
      </div>
      <div class="grid gap-2 grid-cols-2 bg-gray-100 px-2 py-3">
        <div class="text-gray-700 text-lg font-EffraL font-semibold">
          Code ATC
        </div>
        <div class="text-blackdok text-lg font-EffraR">
          {{ data.atc || "--" }}
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
const modal = () => import("@/views/global-components/modal.vue");
import algoliasearch from "algoliasearch/lite";

export default {
  data() {
    return {
      searchClient: algoliasearch(
        "2BJUOM1BDB",
        "7906f008c0346b78f39d051fa293387f"
      ),
      modalIsOpen: false,
      data: {}
    };
  },
  components: {
    modal
  },
  methods: {
    openDrug(data) {
      this.data = data;
      this.modalIsOpen = true;
    },
    exitModal() {
      this.modalIsOpen = false;
    }
  }
};
</script>
<style lang="scss" socped>
table {
  border-collapse: collapse;
  border-spacing: 0;
}
</style>
